import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Space } from "antd";
import { addOrgFormData } from "../../../redux/actions";
import { getWeb3 } from "../../../helpers/currentWalletHelper";
import MultiSig from "../../../Config/abis/EquinoxMain.json";
import GToken from "../../../Config/abis/GToken.json";
import Eq from "../../../Config/abis/EquinoxToken.json";
import { CREATE_DAO } from "../../../utils";
import { getMe } from "../../../services/dashboard";
import Web3 from "web3";
import {
  AwaitingApproval,
  MultiSignature,
  ContinuePay,
  GasError,
  DuplicateError,
} from "../../../components/modals";
import { connect } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getDeployPrice, getMultiSigWallet } from "../../../helpers/getterFunctions";
import { getContractInstance } from "../../../components/Accountmodal";
import contracts from "../../../Config/contracts";
import deployerAbi from "./../../../Config/abis/multisigDeployer.json";
import { getAddress } from "../../../helpers/addressHelper";
import { useNavigate } from "react-router-dom";

function TreasuryStepThird(props) {
  const [wallets, setWallets] = useState([]);
  const eqxAdd = "0x54040960e09fb9f1dd533d4465505ba558693ad6"; // fetch this address (in this file and in org.jsx file) form pages/Config/contracts.js
  const [multiSigAdd, setMultiSigAdd] = useState("");
  const [awaiting, setAwaiting] = useState(false);
  const [multiSign, setMultiSign] = useState(false);
  const [pay, setPay] = useState(false);
  const [gasError, setGasError] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [form] = Form.useForm();
  let navigate = useNavigate();

  useEffect(() => {
    form.resetFields();
  }, [props.walletInfo?.wallet, props.orgFormdata?.email]);
  const onFinish = async (fields) => {
    var values = fields.members;
    let hasDuplicatewallet =
      values.map((v) => v.wallet).length >
        new Set(values.map((v) => v.wallet)).size
        ? true
        : false;

    if (hasDuplicatewallet) {
      message.error("Duplicate wallet entry found.!!");
      return;
    }
    props.dispatch(addOrgFormData(fields));
    deploy(fields.members);
  };

  const deploy = async (member) => {
    let price = await getDeployPrice()
    console.log("here11111");
    let web3 = await getWeb3();
    if (member?.length < 3) {
      alert("Minimum 3 members required");
      return;
    }
    setAwaiting(true);
    let contract = new web3.eth.Contract(MultiSig); //MultiSig
    console.log("contract", contract);

    let accounts = await web3.eth.getAccounts();
    console.log("accounts", accounts);
    let repeated = false;
    member.forEach((mem) => {
      if (wallets.includes(mem.wallet)) {
        // alert("Member already exist")
        setAwaiting(false);
        repeated = true;
        setWallets([]);
        return;
      }
      wallets.push(mem.wallet);
    });
    if (repeated) {
      return;
    }
    try {
      let deployer = await getContractInstance(
        getAddress(contracts?.multisigDeployer),
        deployerAbi
      );
      console.log("deployer contract is--->", deployer);

      let gasAmount = await deployer.methods
        .deploy(wallets, eqxAdd)
        .estimateGas({
          from: localStorage.getItem("eqx_connected_account"),
          value: price,
        });

      console.log("gas ammount is---->", gasAmount, typeof gasAmount);
      const gasPriceWei = await web3.eth.getGasPrice();

      console.log("gas price is---->", gasPriceWei);
      await deployer.methods
        .deploy(wallets, eqxAdd)
        .send({
          from: localStorage.getItem("eqx_connected_account"),
          value: price,
          gas: gasAmount,
          gasPrice: gasPriceWei,
        })
        .on("error", (err) => {
          console.log("error is", err);
          setGasError(true);
        })
        .then(async (receipt) => {
          console.log("receipt._address", receipt);
          localStorage.setItem(
            localStorage.getItem("eqx_connected_account"),
            receipt._address
          );
          localStorage.setItem(
            getMultiSigWallet() + "-wallets",
            member?.toString()
          );

          // addOrg(member);

          props.nextStep();
          setAwaiting(false);
          alert("Vault Created successfully");
          setPay(true);
        })
        .catch((Err) => {
          if (Err.code == "4001") {
            alert("User denied");
          }
          setAwaiting(false);
          setPay(false);
          setWallets([]);
        });
    } catch (error) {
      alert("Error", error.message);
      console.log(error.message);
      setAwaiting(false);
      setPay(false);
    }
  };

  return (
    <div className="mt-5 flex flex-col items-center justify-center">
      <div className=" mb-12">
        <h1 className="text-2xl font-bold mb-4">STEP 3</h1>
        <p className="text-base">Deployer KYC</p>
      </div>
      <div className="form w-full lg:w-1/2 welcome-card rounded-lg p-6">
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            members: [
              {
                wallet: props.walletInfo.wallet,
                name: props.orgFormdata?.deployer_name,
                email: props.orgFormdata?.email,
              },
            ],
          }}
          form={form}
        >
          <Form.List name="members">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, i) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "wallet"]}
                      validateTrigger="onBlur"
                    >
                      <Input placeholder="Wallet" disabled={i === 0} />
                    </Form.Item>

                    {i !== 0 && (
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    )}
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Member
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <div className="flex">
            <Button
              className="mx-0 flex gap-1 mx-auto bordered border-gray-400 text-gray-400"
              type="primary"
              onClick={() => props.previousStep()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-arrow-narrow-left"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l14 0"></path>
                <path d="M5 12l4 4"></path>
                <path d="M5 12l4 -4"></path>
              </svg>
              Previous
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="ml-0 flex gap-1 mx-auto grad-btn border-0"
            >
              Deployy
            </Button>
          </div>
        </Form>
      </div>
      <AwaitingApproval open={awaiting} setOpen={setAwaiting} />
      <GasError open={gasError} setOpen={setGasError} />
      <ContinuePay open={pay} setOpen={setPay} />
      <MultiSignature open={multiSign} setOpen={setMultiSign} />
      <DuplicateError open={duplicate} setOpen={setDuplicate} />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    orgFormdata: state.orgFormdata,
  };
};

export default connect(mapStateToProps)(TreasuryStepThird);
