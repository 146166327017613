import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MainLayout from './layout/main-layout/MainLayout';
import { getMe } from './services/dashboard';
import { setMultiSigAdd } from './helpers/getterFunctions';
import { ClickToComponent } from 'click-to-react-component'


const App = (props) => {
  useEffect(() => {
    const account = localStorage.getItem("eqx_connected_account");
    if (account) {
      getMe(account);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await setMultiSigAdd();
    };
    if (localStorage.getItem("eqx_connected_account")) fetch();
  }, [localStorage.getItem("eqx_connected_account")]);

  return (
    <>
      <ClickToComponent />
      <MainLayout />
    </>
  );
};

export default App;