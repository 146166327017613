import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ConfirmModal from "../../../../components/modals/ConfirmModal";
import { getAccount, getWeb3 } from "../../../../helpers/currentWalletHelper";
import multiSigv2Abi from "./../../../../Config/abis/EquinoxMain.json";
import store from "../../../../redux/store";
import { updateSpinner } from "../../../../redux/actions";
import {
  getMultiSigWallet,
  getTransferRequests,
  getTransferVotes,
} from "../../../../helpers/getterFunctions";
import { getContractInstance } from "../../../../components/Accountmodal";
import DetailModal from "../../../../components/DetailModal";

function PendingRequest(props) {
  const { org, auth } = props;
  const [currentValues, setCurrentValues] = useState({});
  const [transferApproveModal, setTransferApproveModal] = useState(false);
  const [transferDissApproveModal, setTransferDissApproveModal] =
    useState(false);
  const [transferFinalizeModal, setTransferFinalizeModal] = useState(false);
  const [view, setView] = useState(false);
  const [voters, setVoter] = useState([]);
  const [votingLoading, setVotingLoading] = useState(false);
  const [message, setMessage] = useState("Vote has been registered");
  const [errPopup, setErrPopup] = useState(false);
  const [action, setAction] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [reload, setReload] = useState(false);
  const [selectedPropId, setSelectedPropId] = useState();

  const getVotes = async (index) => {
    let votes = await getTransferVotes(index);
    console.log("votttt", votes);
    setVoter(votes);
  };

  useEffect(() => {
    getProposals();
  }, [reload]);

  const getProposals = async () => {
    let proposals = await getTransferRequests();
    setProposals(proposals?.pendingProposals);
  };
  const transferApproveHandler = (values) => {
    console.log(values);
    setTransferApproveModal(false);
    approve(values.to, selectedPropId);
  };

  const transferDissApproveHandler = (values) => {
    console.log(values);
    setTransferDissApproveModal(false);
    disApprove(values.to, selectedPropId);
  };
  const transferFinalizeHandler = (values) => {
    console.log(values);
    setTransferFinalizeModal(false);
    finalize(values.to, values.tokenAddress, selectedPropId);
  };
  const approve = async (to, id) => {
    try {
      let account = await getAccount();
      console.log("here");
      let multiSigAddr = getMultiSigWallet();
      const contract = await getContractInstance(multiSigAddr, multiSigv2Abi);
      const isSigned = await contract.methods
        .isTransferProposalSigned(id, account)
        .call();
      console.log(isSigned);
      const isDisapprovedSigned = await contract.methods
        .isTransferProposalDisapproved(id, account)
        .call();
      if (isDisapprovedSigned) {
        alert("Proposal has been disapproved by one of the team member");
      } else {
        if (!isSigned) {
          store.dispatch(updateSpinner(true));
          let gas;
          let gasPrice;
          try {
            gas = await contract.methods.signTransferProposal(id).estimateGas({
              from: account,
            });
            let web3 = await getWeb3();
            gasPrice = await web3.eth.getGasPrice();
          } catch (e) {
            console.log("err", e);
            store.dispatch(updateSpinner(false));
          }

          await contract.methods
            .signTransferProposal(id)
            .send({ from: account, gas, gasPrice })
            .then(async (result) => {
              console.log(result);
              store.dispatch(updateSpinner(false));
              setMessage("Your Vote has been registered");
              setAction(true);
              setTimeout(() => {
                setAction(false);
              }, 500);
            }).catch((error) => {
              console.log(error);
              store.dispatch(updateSpinner(false));
            });
        } else {
          alert("Vote has already been registered");
        }
      }
    } catch (Err) {
      console.log("Err", Err);
      alert(Err.toString())
    }
  };
  const disApprove = async (to, id) => {
    try {
      let web3 = await getWeb3();

      let account = await getAccount();
      let multiSigAddr = getMultiSigWallet();
      const contract = await getContractInstance(multiSigAddr, multiSigv2Abi);
      const isAprroved = await contract.methods
        .isTransferProposalSigned(id, account)
        .call();
      const isSigned = await contract.methods
        .isTransferProposalDisapproved(id, account)
        .call();
      console.log(isSigned);
      if (isAprroved) {
        alert("Your vote already registered");
      } else {
        if (!isSigned) {
          store.dispatch(updateSpinner(true));
          let gas;
          let gasPrice;
          try {
            gas = await contract.methods
              .disapproveTransferProposal(id, getMultiSigWallet())
              .estimateGas({
                from: account,
              });
            let web3 = await getWeb3();
            gasPrice = await web3.eth.getGasPrice();
          } catch (e) {
            console.log("err", e);
            store.dispatch(updateSpinner(false));
          }

          await contract.methods
            .disapproveTransferProposal(id, getMultiSigWallet())
            .send({ from: account, gas, gasPrice })
            .then(async (result) => {
              console.log(result);
              store.dispatch(updateSpinner(false));
              setMessage("Your Vote has been registered");
              window.location.reload();
              setAction(true);
              setTimeout(() => {
                setAction(false);
              }, 500);
            }).catch((error) => {
              console.log(error);
              store.dispatch(updateSpinner(false));
            });
        } else {
          alert("Proposal has been disapproved");
        }
      }
    } catch (Err) {
      console.log("Err", Err);
      alert(Err.toString())
    }
  };

  const finalize = async (to, tokenAddress, id) => {
    try {
      let web3 = await getWeb3();

      let account = await getAccount();
      let multiSigAddr = getMultiSigWallet();
      const contract = await getContractInstance(multiSigAddr, multiSigv2Abi);
      // const {
      //   data: { response: indexListOftransferProposal },
      // } = await axios.post(`${process.env.REACT_APP_API_URL}/get_index_list`, {
      //   org_id: org?.org?.id,
      //   type: "transfer_proposal",
      // });
      // console.log(indexListOftransferProposal);

      // const propIndex =
      //   indexListOftransferProposal?.filter(
      //     (val) => Number(val.data) === id
      //   )[0].index_number - 1;
      // console.log(propIndex);
      const isFullySigned = await contract.methods
        .transferProposalSignerRequirementMet(id)
        .call();
      console.log(isFullySigned);
      const isDisapprovedSigned = await contract.methods
        .isTransferProposalDisapproved(id, account)
        .call();
      if (isDisapprovedSigned) {
        alert("Proposal has been disapproved");
      } else {
        if (isFullySigned) {
          console.log(to);
          store.dispatch(updateSpinner(true));
          let gas;
          let gasPrice;
          try {
            gas = await contract.methods
              .finalizeTransferProposal(id)
              .estimateGas({
                from: account,
              });
            let web3 = await getWeb3();
            gasPrice = await web3.eth.getGasPrice();
          } catch (e) {
            console.log("err", e);
            store.dispatch(updateSpinner(false));
          }
          await contract.methods
            .finalizeTransferProposal(id)
            .send({ from: account, gas, gasPrice })
            // .then((result) => {
            //   console.log(result);
            //   axios
            //     .post(`${process.env.REACT_APP_API_URL}/finalize_fund_transfer`, {
            //       id: id,
            //       status: 1,
            //     })
            .then((res) => {
              console.log(res);
              store.dispatch(updateSpinner(false));
              setMessage("Proposal Finalize successfully");
              window.location.reload();
              setAction(true);
              // setTimeout(() => {
              //   setAction(false);
              //   window.location.reload();
              // }, 500);
            }).catch((error) => {
              console.log(error);
              store.dispatch(updateSpinner(false));
            });
          setReload(!reload);
          // });
        } else {
          alert("All members have not approved this transaction");
        }
      }
    } catch (Err) {
      console.log("Err", Err);
      alert(Err.toString())
    }
  };
  return (
    <div>
      {proposals &&
        proposals?.length > 0 &&
        proposals?.map((pro, index) => {
          console.log("proposals", proposals);
          return (
            <div className="welcome-card rounded-lg p-6 mb-6 text-black">
              <div className="flex gap-6 justify-between">
                <div>
                  Receiver: {pro.to}
                  <br />
                  Description: {pro.description}
                </div>
                <div>
                  Amount: {pro.tokenAmount} {pro.symbol}
                  {/* {org.project[0].token_name} */}
                </div>
                <div>
                  {!pro.finalized && (
                    <span
                      onClick={() => {
                        setCurrentValues(pro);
                        setSelectedPropId(pro?.reqId);
                        setTransferApproveModal(true);
                      }}
                      className="approve"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="text-green-600 icon icon-tabler icon-tabler-check"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M5 12l5 5l10 -10"></path>
                      </svg>
                    </span>
                  )}
                  {pro.finalized && (
                    <span
                      // onClick={() => approve(index)}
                      className="approved"
                    >
                      Approved
                    </span>
                  )}
                </div>
                <div
                  onClick={() => {
                    setCurrentValues(pro);
                    setSelectedPropId(pro?.reqId);
                    setTransferDissApproveModal(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-red-500 icon icon-tabler icon-tabler-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M18 6l-12 12"></path>
                    <path d="M6 6l12 12"></path>
                  </svg>
                </div>
                {/* <div>3 Days</div> */}
                <div className="flex gap-3">
                  {!pro.finalized && (
                    <Button
                      type="primary"
                      className="grad-btn border-0"
                      onClick={() => {
                        setCurrentValues(pro);
                        setSelectedPropId(pro?.reqId);
                        setTransferFinalizeModal(true);
                      }}
                    >
                      Finalize
                    </Button>
                  )}
                  <Button
                    type="primary"
                    className="grad-btn border-0"
                    onClick={async () => {
                      setCurrentValues(pro);
                      await getVotes(pro?.reqId);
                      setView(true);
                      // getVotingList(pro, 4);
                    }}
                  >
                    View
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      <ConfirmModal
        title="Are you sure you want to Approve!"
        // note=""
        // note_bracket=""
        open={transferApproveModal}
        setOpen={setTransferApproveModal}
        handler={transferApproveHandler}
        currentValues={currentValues}
      />
      <ConfirmModal
        title="Are you sure you want to Disapprove!"
        // note=""
        // note_bracket=""
        open={transferDissApproveModal}
        setOpen={setTransferDissApproveModal}
        handler={transferDissApproveHandler}
        currentValues={currentValues}
      />
      <ConfirmModal
        title="Are you sure you want to Finalize!"
        // note=""
        // note_bracket=""
        open={transferFinalizeModal}
        setOpen={setTransferFinalizeModal}
        handler={transferFinalizeHandler}
        currentValues={currentValues}
      />
      <DetailModal
        open={view}
        setOpen={setView}
        handleOpen={() => setView(true)}
        handleClose={() => setView(false)}
        voters={voters}
        votingLoading={votingLoading}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    org: state.org,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(PendingRequest);
