import {
  Breadcrumb,
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  getMultiSigWallet,
  getMultSigAbi,
  getTeamMembers,
  setMultiSigAdd,
} from "../../helpers/getterFunctions";
import {
  approveChangePercent,
  disapproveChangePercent,
  finalizeChangePercent,
  getPercentChangeProposal,
  getSignedPercentChangeProposal,
  setNewPercent,
} from "../../helpers/setterFunctions";
import { getAccount, getWeb3 } from "../../helpers/currentWalletHelper";
import DetailModal from "../../components/DetailModal";
import toast from "react-hot-toast";
import { getContractInstance } from "../../components/Accountmodal";
import store from "../../redux/store";
import { updateSpinner } from "../../redux/actions";

function ChangePercents() {
  const [view, setView] = useState(false);
  const [open, setOpen] = useState(false);
  const [voters, setVoter] = useState([]);
  const [votingLoading, setVotingLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const [isOdd, setIsOdd] = useState(false);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedPercent, setSelectedPercent] = useState(75);
  const [proposals, setProposals] = useState();
  // const proposals = [{ proposalType: "Crypto", newPercent: "50", finalized: '', disapproved: '' }, { proposalType: "Block", newPercent: "50", finalized: 1, disapproved: '' }, { proposalType: "Ether", newPercent: "50", finalized: '', disapproved: '' }, { proposalType: "Bitcoin", newPercent: "50", finalized: '', disapproved: 1 }]
  // console.log(proposals.proposalType);
  const [votes, setVotes] = useState();
  const [count, setCount] = useState(1);
  useEffect(() => {
    const fetch = async () => {
      if (getMultiSigWallet()) {
        let members = await getTeamMembers();
        if (members.length % 2 !== 0) {
          setIsOdd(true);
        }
        let list = await getPercentChangeProposal();
        setProposals(list);
      }
    };
    fetch();
  }, [getMultiSigWallet(), reload]);

  const getVoteCount = async (id) => {
    if (getMultiSigWallet()) {
      try {
        let signers = await getTeamMembers();
        let _votes = [];
        for (let i = 0; i < signers.length; i++) {
          let list = await getSignedPercentChangeProposal(id, signers[i]);
          console.log("signers[i]", signers[i], list);
          let key = signers[i];
          _votes.push({ member: [key], hasVoted: list ? "Yes" : "No" });
        }
        console.log("vooottees", _votes);
        setCount(count + 1);
        setVotes(_votes);
      } catch (Err) {
        console.log("Err", Err);
      }
    }
  };
  // useEffect(() => {
  //     const fetch = async () => {
  //         if (getMultiSigWallet()) {
  //             try {
  //                 let signers = await getTeamMembers()
  //                 let _votes = votes;
  //                 for (let i = 0; i < signers.length; i++) {
  //                     let list = await getSignedPercentChangeProposal(0, signers[i])

  //                     let key = signers[i]
  //                     _votes.push({ [key]: list })
  //                 }
  //                 setVotes(_votes)
  //             }

  //             catch (Err) {
  //                 console.log("Err", Err)
  //             }
  //         }
  //     }
  //     fetch()
  // }, [getMultiSigWallet()])

  return (
    <>
      <div className="main-sec border-b border-gray-700 md:p-4 rounded-3xl">
        <div className="container mx-auto md:px-4 py-5 text-center w-[98%] md:w-[90%]">
          <div className=" mb-12 max-lg:text-center flex gap-2">
            <div className="flex mx-auto gap-4 ">
              <div>
                <h1 className="mt-4 ">Select Type :</h1>
              </div>
              <div>
                <select
                  className="p-4 w-20 md:w-40 rounded-lg"
                  onSelect={(e) => {
                    console.log("e", e);
                  }}
                  onChange={(e) => {
                    console.log("ee", e.target.value);
                    setSelectedType(e.target.value);
                  }}
                >
                  <option value="0">Remove Member Required Signers</option>
                  {/* <option value="2" >Disapprove Remove Member Required Signers</option> */}
                  <option value="1">Add Member Required Signers</option>
                  <option value="2">Transfer Required Signers</option>
                </select>
              </div>
            </div>

            <div className="flex mx-auto md:gap-4 gap-2 text-center align-middle ">
              <div>
                <h1 className="align-middle mt-4">Select Percentage :</h1>
              </div>
              <div>
                <select
                  className="p-4 rounded-lg"
                  onSelect={(e) => {
                    console.log("e", e);
                  }}
                  onChange={(e) => {
                    console.log("ee", e.target.value);
                    setSelectedPercent(e.target.value);
                  }}
                >
                  {/* <option disabled={isOdd} value="50">50</option> */}
                  <option value="75">75</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
          <div className="text-right md:pr-12">
            <button
              className="px-8 py-2 rounded-md grad-btn text-gray-50 "
              onClick={async () => {
                setIsLoading(true);
                try {
                  let account = await getAccount();
                  let contract = await getContractInstance(
                    getMultiSigWallet(),
                    getMultSigAbi()
                  );
                  let gas = await contract.methods
                    .CreateRequiredPercentProposal(
                      selectedType,
                      selectedPercent
                    )
                    .estimateGas({ from: account, value: 0 });
                  let web3 = await getWeb3();
                  let gasPrice = await web3.eth.getGasPrice();
                  try {
                    contract.methods
                      .CreateRequiredPercentProposal(
                        selectedType,
                        selectedPercent
                      )
                      .send({ from: account, value: 0, gas, gasPrice })
                      .then(async (result) => {
                        console.log(result);
                        toast.success("Proposal Sent Successfully");
                        setReload(!reload);
                        setIsLoading(false);
                      })
                      .catch((error) => {
                        console.log("error is------>", error);
                        setIsLoading(false);
                        alert(error?.toString());
                      });
                  } catch (err) {
                    console.log("err111", err);
                    setIsLoading(false);
                    alert("Error" + err.toString());
                  }
                } catch (err) {
                  console.log("err2222", err, typeof err);
                  if (err?.toString().includes("Active Proposal")) {
                    alert("There are some active proposals");
                  } else {
                    alert("Error" + err.toString());
                  }
                  setIsLoading(false);
                }
              }}
              disabled={isLoading}
            >
              {isLoading ? "Loading.." : "Submit Proposal"}
            </button>
          </div>
        </div>

        <div className=" mx-auto">
          <table className=" p-4 w-[90%] md:w-full">
            <tr className="md:p-4 text-left md:gap-8 flex w-[96%]  md:w-full">
              <th className="md:w-[15%] w-10 mr-6 text-xs">Proposal Type</th>
              <th className="md:w-[15%] w-10 mr-6 md:mr-10 text-xs">
                Proposal Percentage
              </th>
              <th className="md:w-[15%] w-10 mr-3 text-xs">Sign</th>
              <th className="md:w-[15%] w-10 mr-9 text-xs">
                <button>Disapprove</button>
              </th>
              <th className="md:w-[15%] w-10 mr-6 text-xs">
                <button>Finalize</button>
              </th>
              {/* <th className='md:w-[15%] w-10  mr-6 text-xs'><button>Votes</button></th> */}
            </tr>
            {proposals &&
              proposals?.length > 0 &&
              proposals?.map((p, i) => {
                console.log("Proposals", proposals);
                return (
                  <tr className="flex gap-2 p-4 my-auto text-left bg-white rounded-2xl shadow-md mb-2 w-[95%] md:w-full">
                    <td className="md:w-[17%] w-10 mr-3 align-middle">
                      {p.proposalType}
                    </td>
                    <td className="md:w-[15%] w-10 mr-3">{p.newPercent}</td>
                    <td className="md:w-[15%] w-10 mr-3">
                      {!p?.finalized && !p?.disapproved && (
                        <span
                          onClick={async () => {
                            try {
                              store.dispatch(updateSpinner(true));
                              let account = await getAccount();
                              let contract = await getContractInstance(
                                getMultiSigWallet(),
                                getMultSigAbi()
                              );
                              let gas = await contract.methods
                                .signChangePercentProposal(i)
                                .estimateGas({ from: account, value: 0 });
                              let web3 = await getWeb3();
                              let gasPrice = await web3.eth.getGasPrice();
                              let res = await contract.methods
                                .signChangePercentProposal(i)
                                .send({ from: account, gas, gasPrice })
                                .then(async (result) => {
                                  console.log(result);
                                  setReload(!reload);
                                  setIsLoading(false);
                                   alert(result?.toString());
                                  store.dispatch(updateSpinner(false));
                                  return result;
                                })
                                .catch((error) => {
                                  console.log("error is------>", error);
                                  setIsLoading(false);
                                  alert(error?.toString());
                                  store.dispatch(updateSpinner(false));
                                });
                            } catch (err) {
                              console.log("err", err);
                              alert("Error" + err.toString());
                              store.dispatch(updateSpinner(false));
                            }
                          }}
                          className="approve md:mr-32"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="text-green-600 icon icon-tabler icon-tabler-check"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <path d="M5 12l5 5l10 -10"></path>
                          </svg>
                        </span>
                      )}
                      {p?.finalized && (
                        <span
                          // onClick={() => approve(index)}
                          className="approved md:mr-20"
                        >
                          Approved
                        </span>
                      )}
                      {p?.disapproved && (
                        <span
                          // onClick={() => approve(index)}
                          className="approved md:ml-44"
                        >
                          Disapproved
                        </span>
                      )}
                    </td>
                    <td className="md:w-[15%] w-10 mr-3">
                      {!p.finalized && !p.disapproved && (
                        <div
                          onClick={async () => {
                            try {
                              store.dispatch(updateSpinner(true));
                              let account = await getAccount();
                              let contract = await getContractInstance(
                                getMultiSigWallet(),
                                getMultSigAbi()
                              );
                              let gas = await contract.methods
                                .disapproveChangePercentProposal(i)
                                .estimateGas({ from: account, value: 0 });
                              let web3 = await getWeb3();
                              let gasPrice = await web3.eth.getGasPrice();

                              let res = contract.methods
                                .disapproveChangePercentProposal(i)
                                .send({ from: account, gas, gasPrice })
                                .then(async (result) => {
                                  console.log(result);
                                  toast.success(
                                    "Proposal Disapproved Successfully"
                                  );
                                  setReload(!reload);
                                  setIsLoading(false);
                                  store.dispatch(updateSpinner(false));
                                  return result;
                                })
                                .catch((error) => {
                                  console.log(
                                    "error is------>",
                                    JSON.parse(error?.toString()).message
                                  );
                                  store.dispatch(updateSpinner(false));
                                  alert(error?.toString());
                                  setIsLoading(false);
                                });
                            } catch (Err) {
                              console.log("err", Err);
                              alert("Error" + Err.toString());
                              store.dispatch(updateSpinner(false));
                            }

                            // await disapproveChangePercent(i, account)
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="text-red-500 icon icon-tabler icon-tabler-x"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <path d="M18 6l-12 12"></path>
                            <path d="M6 6l12 12"></path>
                          </svg>
                        </div>
                      )}
                    </td>
                    <td className="md:w-[15%] w-8 -ml-6 mr-8">
                      {!p.finalized && !p.disapproved && (
                        <button
                          type="primary"
                          className="grad-btn border-0 text-gray-100  md:px-4 px-2 py-1 rounded-md text-sm "
                          onClick={async () => {
                            try {
                              store.dispatch(updateSpinner(true));
                              let account = await getAccount();
                              let contract = await getContractInstance(
                                getMultiSigWallet(),
                                getMultSigAbi()
                              );
                              let gas = await contract.methods
                                .finalizeChangePercentProposal(i)
                                .estimateGas({ from: account, value: 0 });
                              let web3 = await getWeb3();
                              let gasPrice = await web3.eth.getGasPrice();

                              let res = contract.methods
                                .finalizeChangePercentProposal(i)
                                .send({ from: account, gas, gasPrice })
                                .then(async (result) => {
                                  console.log(result);
                                  toast.success("Proposal Finalize successfully")
                                  store.dispatch(updateSpinner(false));
                                  return result;
                                })
                                .catch((error) => {
                                  console.log("error is------>", error);
                                  store.dispatch(updateSpinner(false));
                                  alert(error?.toString());
                                  setIsLoading(false);
                                });
                            } catch (Err) {
                              console.log("err123", Err);
                              alert("Error" + Err.toString());
                              store.dispatch(updateSpinner(false));
                            }
                          }}
                        >
                          Finalize
                        </button>
                      )}
                    </td>
                    <td className="md:w-[15%] w-8 ">
                      <button
                        type="primary"
                        className="grad-btn border-0 text-gray-100 md:px-4 px-2 py-1 rounded-md text-sm"
                        onClick={async () => {
                          await getVoteCount(i);
                          setView(true);
                        }}
                      >
                        <span className=" md:block mr-1">View</span>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
        <br></br>
        {/* <div className='absolute z-100 bg-white top-[300px] left-[400px] rounded-3xl p-4'>
                    <div className='justify-between flex text-xl'>
                        <h4>Votes</h4>
                        <h1>X</h1>
                    </div>
                    <table className='mt-2  border mx-auto w-[500px]'>
                        <tr className=' border-2'>
                            <th className=' w-[30%]'>Signer</th>
                            <th className='w-[10%]'>Is Voted</th>
                        </tr>
                        {count && votes && votes?.length > 0 && votes?.map((v, i) => {
                            console.log("v.value", v.value)
                            return (
                                <tr className=' mt-6 pl-5'>
                                    <td className='w-[40%] '>{v.key} 0xb8DF5B1FF1eb00de70516E480A6c73A6CeEd47E5</td>
                                    <td className='w-[10%] '>{v.value ? "Yes" : "No"}</td>
                                </tr>
                            )
                        })}
                    </table>
                </div> */}
        <DetailModal
          open={view}
          setOpen={setView}
          handleOpen={() => setView(true)}
          handleClose={() => setView(false)}
          voters={votes}
          votingLoading={votingLoading}
        />
      </div>
      {/* <div className='mt-6'>
                <div className="flex gap-3">
                    <div>
                        <Button
                            // onClick={() => setModal2Open(true)}
                            type="primary"
                            className="grad-btn border-0 mr-3"
                        >
                            Create New Request
                        </Button>
                        <Button
                            // onClick={() => setAddTokenModalOpen(true)}
                            type="primary"
                            className="grad-btn border-0"
                        >
                            Add New Token
                        </Button>
                    </div>
                </div>
            </div> */}
    </>
  );
}

export default ChangePercents;
