const contracts = {
  usdt: {
    56: "0x55d398326f99059ff775485246999027b3197955",
    97: "0x18e44547B4feD25b8d092237181c0467257cE6E8",
    5777: "0xDBb5Ca8DD962cCBE5869F827b103A87Ef079B550",
    80001: "0xccFE4E82f65EE03C0E86B180A23eD1DB7766b415",
    type: 1,
  },
  eth: {
    56: "0x1ee38d535d541c55c9dae27b12edf090c608e6fb",
    97: "0xea6838c9e17ed7334682dF1d96A7852cCE370eFe",
    type: 4,
  },
  btc: {
    56: "0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F",
    97: "0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F",
    5777: "0xDBb5Ca8DD962cCBE5869F827b103A87Ef079B550",
    type: 3,
  },
  busd: {
    56: "",
    97: "0xF188Fcd3e22FA9C3204A285b7bbAb4F3cB8b9c00",
    5777: "0xDBb5Ca8DD962cCBE5869F827b103A87Ef079B550",
    type: 5,
  },
  crowdsale: {
    56: "",
    97: "0xC067B4Cc56e5Fb2Bb285587ea4c5f2F20d2C3dd3",
    5777: "0xDBb5Ca8DD962cCBE5869F827b103A87Ef079B550",
  },
  bnb: {
    56: "",
    97: "0x3dC969295b2bF20603523bDB0f510A4B1BA95275",
    5777: "0xDBb5Ca8DD962cCBE5869F827b103A87Ef079B550",
    type: 2,
  },
  eqx: {
    56: "",
    97: "0x54040960e09fb9f1dd533d4465505ba558693ad6", //0xF768C1253A7321Cd449Af066708eFFD9A852618b
    5777: "0xDBb5Ca8DD962cCBE5869F827b103A87Ef079B550",
    80001: "",
  },
  dex: {
    56: "",
    97: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3", //0xF768C1253A7321Cd449Af066708eFFD9A852618b
    5777: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  },
  multisigDeployer: {
    56: "",
    97: "0x99A8C71aB78cb8da314Da51d769F86624a552785",
    80001: "0x4EaA13a99789959C864Ce07BC30260e68982cdc5",
  },
};

export default contracts;
